import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  AutocompleteSelectComponent
} from 'src/app/kernel/form/modules/inputs/autocomplete-select/componenets/autocomplete-select/autocomplete-select.component';
import { FormErrorMessageModule } from "src/app/kernel/form/modules/tools/form-error-message/form-error-message.module";
import {DropdownModule} from "primeng/dropdown";
import { MultiSelectModule } from "primeng/multiselect";
import {TranslateModule} from "@ngx-translate/core";
import { AutoCompleteModule } from 'primeng/autocomplete';

@NgModule({
    declarations: [AutocompleteSelectComponent],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      FormErrorMessageModule,
      DropdownModule,
      MultiSelectModule,
      TranslateModule,
      AutoCompleteModule
    ],
    exports: [
      AutocompleteSelectComponent,
      FormsModule,
      ReactiveFormsModule,
    ]
})

export class AutocompleteSelectModule {

}
