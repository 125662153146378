import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { VirtualContainerComponent } from "./components/virtual-container/virtual-container.component";
import { SvgIconModule } from "../../tools/svg-icon/svg-icon.module";

@NgModule({
  declarations: [VirtualContainerComponent],
  imports: [CommonModule, SvgIconModule],
  exports: [VirtualContainerComponent],
})
export class VirtualContainerModule {}
