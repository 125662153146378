import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormControlOutletDirective } from "./directives/form-control-outlet.directive";

@NgModule({
  declarations: [FormControlOutletDirective],
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  exports: [FormControlOutletDirective],
})
export class FormControlOutletModule {}
