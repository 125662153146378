import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FiscalInputComponent
} from "src/app/kernel/form/modules/inputs/fiscal-input/components/fiscal-input/fiscal-input.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FormErrorMessageModule} from "src/app/kernel/form/modules/tools/form-error-message/form-error-message.module";
import {InputTextModule} from "primeng/inputtext";
import {FormModule} from "../../../form.module";
import { TranslationModule } from "../../../../translations/modules/translation.module";
import {ButtonModule} from "../../../../shared/modules/button/button.module";
import {FormStepperModule} from "../../tools/form-stepper/form-stepper.module";
import {SimpleInputsModule} from "../simple-inputs/simple-inputs.module";
import {ValidatorModule} from "../../tools/validators/validator.module";
import {AutocompleteSelectModule} from "../autocomplete-select/autocomplete-select.module";


@NgModule({
  declarations: [
    FiscalInputComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        FormErrorMessageModule,
        ReactiveFormsModule,
        InputTextModule,
        TranslationModule,
        ButtonModule,
        FormStepperModule,
        SimpleInputsModule,
        ValidatorModule,
        AutocompleteSelectModule
    ],
  exports: [
    FiscalInputComponent
  ],
})
export class FiscalInputModule { }
