import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';

const routes: Route[] = [
  {
    path: 'operators',
    loadChildren: () =>
      import('./modules/operators/operator.module').then(
        ({ OperatorsModule }) => OperatorsModule
      ),
  },
  {
    path: 'drivers',
    loadChildren: () =>
      import('./modules/drivers/drivers.module').then(
        (m) => m.DriversModule
      ),
  },
  {
    path: 'roles',
    loadChildren: () =>
      import('./modules/roles/roles.module').then(
        ({ RolesModule }) => RolesModule
      ),
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CmRoutingModule {}
