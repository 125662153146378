import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationModule } from 'src/app/kernel/translations/modules/translation.module';
import { SvgIconModule } from 'src/app/kernel/tools/svg-icon/svg-icon.module';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import {DownloadFilesModule} from "src/app/kernel/tools/download-files/download-files.module";
import { RouterModule } from '@angular/router'; 
import { NotificationModule } from 'src/app/kernel/notifications/notification.module';



@NgModule({
    declarations: [TopNavComponent],
    imports: [
        CommonModule,
        TranslationModule,
        SvgIconModule,
        DownloadFilesModule,
        RouterModule,
        NotificationModule
    ],
    exports: [TopNavComponent]
})
export class TopNavModule { }
