import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SideNavModule} from "src/app/layout/side-nav/side-nav.module";
import {DashboardLayoutComponent} from "src/app/layout/dashboard-layout/dashboard-layout/dashboard-layout.component";
import {PopupWrapperModule} from "src/app/kernel/popups/popup-wrapper/popup-wrapper.module";
import {RouterModule} from "@angular/router";
import {TopNavModule} from "src/app/layout/top-nav/top-nav.module";


@NgModule({
  declarations: [DashboardLayoutComponent],
  imports: [
    CommonModule,
    TopNavModule,
    SideNavModule,
    PopupWrapperModule,
    RouterModule
  ],
  exports: [ DashboardLayoutComponent ]
})
export class DashboardLayoutModule { }
