import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileNumberComponent } from 'src/app/kernel/form/modules/inputs/mobile-number/components/mobile-number/mobile-number.component';
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { KeyFilterModule } from "primeng/keyfilter";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { ButtonModule } from "primeng/button";
import {FormErrorMessageModule} from "src/app/kernel/form/modules/tools/form-error-message/form-error-message.module";
import {SimpleInputsModule} from "../simple-inputs/simple-inputs.module";
import { ArabicNumberPipe } from 'src/app/kernel/shared/pipes/arabic-number.pipe';
import { TranslationModule } from 'src/app/kernel/translations/modules/translation.module';



@NgModule({
  declarations: [
    MobileNumberComponent,
    ArabicNumberPipe
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormErrorMessageModule,
        DropdownModule,
        InputTextModule,
        KeyFilterModule,
        FormsModule,
        ButtonModule,
        SimpleInputsModule,
        TranslationModule
    ],
  exports: [MobileNumberComponent , ArabicNumberPipe]
})
export class MobileNumberModule { }
