import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {VatInputComponent} from "src/app/kernel/form/modules/inputs/vat-input/components/vat-input/vat-input.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DropdownModule} from "primeng/dropdown";
import {FormErrorMessageModule} from "src/app/kernel/form/modules/tools/form-error-message/form-error-message.module";
import {InputTextModule} from "primeng/inputtext";
import {TranslationModule} from "../../../../translations/modules/translation.module";
import {SimpleInputsModule} from "../simple-inputs/simple-inputs.module";



@NgModule({
  declarations: [
    VatInputComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormErrorMessageModule,
        DropdownModule,
        InputTextModule,
        TranslationModule,
        SimpleInputsModule
    ],
  exports: [
    VatInputComponent
  ]
})
export class VatInputModule { }
