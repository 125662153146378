import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/**
 * NOTE THAT THIS FILE SHOULD ONLY CONTAIN LAZY LOADING ROUTS
 * EACH ROUTE SHOULD POINT TO A MODULE THAT CONTAINS ITS OWN ROUTES
 */
const routes: Routes = [
  {
    path: 'passengers',
    loadChildren: () =>
      import('./modules/passengers/passengers.module').then((m) => m.PassengersModule)
  },
  {
    path: 'companies',
    loadChildren: () =>
      import('./modules/companies/companies.module').then((m) => m.CompaniesModule)
  },
  {
    path: 'employees',
    loadChildren: () => 
      import('./modules/employees/employee.module').then((m) => m.EmployeeModule)
  },
  {
    path: 'referrals',
    loadChildren: () => 
      import('./modules/referrals/referrals.module').then(({ReferralsModule}) => ReferralsModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CrmRoutingModule {}
