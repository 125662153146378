import { inject, Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import {
  firstValueFrom,
  BehaviorSubject,
  map,
  Observable,
  of,
  retry,
  switchMap,
  tap,
} from "rxjs";
import { GraphService } from "../../../graphql/services/graph.service";
import {
  FilterInput,
  FiltersGraphqlInput,
  ListingGraphqlInput,
  PaginationGraphqlInput,
} from "../../../graphql/models";
import { LocalStorageService } from "../../../tools/services/local-storage.service";
import { NotificationInterface } from "../../interfaces/notification.interface";
import { NotificationAction } from "../../tokens/notification-actions.token";

@Injectable({
  providedIn: "root",
})
export class NotificationService {

  private notificationAction = inject(NotificationAction)

  /** 
   * initNotification used to detect if notification already loded don't fetch them from the server.
   * on inifinit scroll initNotification become false
   */
  private initNotifications = new BehaviorSubject<boolean>(false);
  private notifications = new BehaviorSubject<NotificationInterface[]>([]);
  private newNotificationsCount = new BehaviorSubject<number>(0);
  private totalNotificationPages = new BehaviorSubject<number>(0);
  private _page = new BehaviorSubject<number>(1);

  readonly notifications$ = this.notifications.asObservable();
  readonly newNotificationsCount$ = this.newNotificationsCount.asObservable();
  readonly totalNotificationPages$ = this.totalNotificationPages.asObservable();
  readonly initNotifications$ = this.initNotifications.asObservable();
  readonly page$ = this._page.asObservable();

  constructor(
    private graphService: GraphService,
    private localStorageService: LocalStorageService,
    private afMessaging: AngularFireMessaging,
  ) {}

  setInitNotifications(value: boolean) {
    this.initNotifications.next(value);
  }
  setPage(value: number) {
    this._page.next(value);
  }
  getPage() {
    return this._page.value
  }

  listenToBackGroundMessages() {
    const broadcast = new BroadcastChannel('sw-update-channel');
    return new Observable<NotificationInterface>((subscriber) => {
      const onMessage = (event: MessageEvent<{notification: {data: NotificationInterface}}>) => subscriber.next(event.data.notification.data)
      broadcast.addEventListener('message', onMessage);

      return () => {
        broadcast.removeEventListener('message', onMessage);
      }
    })
  }


  /**
   * Request permission from the user to show notifications using the Firebase Cloud Messaging (FCM) service.
   *
   * @returns An observable that emits the permission status of the request, which can be either `'granted'`, `'denied'`
   */
  requestPermission(): Observable<NotificationPermission> {
    return this.afMessaging.requestPermission;
  }

  /**
   * Retrieve the Firebase Cloud Messaging (FCM) token for the device.
   *
   * The function starts by requesting permission from the user to show notifications.
   * Then, it listens to changes in the FCM token using `afMessaging.tokenChanges`.
   * If a valid token is received, it is stored in local storage using the `localStorage.setValue` method.
   *
   * @returns An observable that emits the FCM token for the device, or `null` if the user denies permission to show notifications.
   */
  getToken() {
    return this.requestPermission().pipe(
      switchMap(() => {
        return this.afMessaging.tokenChanges;
      }),
      tap((token: string | null) => {
        if (token) {
          this.localStorageService.set("notification_token", token);
        }
      }),
      // if cached is clear and after login successfully, always get error after permission is granted. so retry 1 solved the issue:
      // TODO: Refactor this.
      retry(1)
    );
  }

  /**
   * Add the Firebase Cloud Messaging (FCM) token for the device to the server.
   *
   * The function starts by checking if the token is already stored in local storage.
   * If it is, the token is used directly. If it's not, the `getToken` function is called to retrieve the token.
   * Then, the `graph.constructMutation` method is called to send the token to the server.
   * If the token is `null`, the function returns an observable that emits `null`.
   *
   * @returns An observable that emits the result of the server request, or `null` if the token is `null`.
   */
  addFirebaseToken() {
    return of(this.localStorageService.get<string>("notification_token")).pipe(
      switchMap(async (cachedFcmToken: string | null) => {
        const fcmToken = await firstValueFrom(this.getToken());
        if (cachedFcmToken && fcmToken !== cachedFcmToken) {
          await firstValueFrom(this.notificationAction.deleteToken(cachedFcmToken));
        }
        return fcmToken;
      }),
      switchMap((token: string | null) =>
        token
          ? this.notificationAction.addToken(token)
          : of(null)
      )
    );
  }

  /**
   * Returns`afMessaging.messages` observable to subscribe to FCM notifications.
   */
  get messages() {
    return this.afMessaging.messages;
  }

  /**
   * Marks a notification as seen.
   * @param id
   */
  markNotificationAsSeen(): Observable<any> {
    return this.graphService.constructMutation<{
      markNotificationAsSeen: { code: number; text: string };
    }>("markNotificationAsSeen", {}, {}, ["code", "text"]);
  }

  /**
   * Marks a notification as clicked.
   * @param id
   */
  markNotificationAsClicked(id: string): Observable<any> {
    return this.graphService.constructMutation<{
      markNotificationAsSeen: { code: number; text: string };
    }>("markNotificationAsClicked", { id: "ID" }, { id }, ["code", "text"]);
  }

  /**
   * Delete a notification.
   * @param id
   */
  deleteNotification(id: string): Observable<any> {
    return this.graphService.constructMutation<{
      DeleteNotification: { code: number; text: string };
    }>("DeleteNotification", { id: "ID" }, { id }, ["code", "text"]);
  }

  /**
   * Retrieve a list of notifications from the API.
   *
   * @param page The page number to retrieve.
   * @param rpp The number of notifications to retrieve per page (defaults to 7).
   * @returns An observable that emits the list of notifications.
   *
   * The function uses the `graph.constructListingQuery` method to send a GraphQL query to the API
   * to retrieve a paginated list of notifications.
   * The response from the API is mapped to extract the list of notifications and
   * update the local `notifications$` subject and the `isLastPage$` subject.
   */
  getNotifications(
    page: number,
    rpp: number = 5,
    reset = true,
    filters:FilterInput[]|undefined=undefined,
  ) {
    return this.initNotifications.value ? this.notifications$ : 
    this.graphService
      .constructQuery<{notifications: {notifications: NotificationInterface[], total: number, new: number,notClickedCount:number}}>(
        [
          "notifications.id",
          "notifications.title",
          "notifications.text",
          "notifications.type",
          "notifications.data",
          "notifications.nid",
          "notifications.key",
          "notifications.created_at",
          "notifications.seen_at",
          "notifications.clicked_at",
          "total",
          "new",
          "notClickedCount",
        ],
        "notifications",
        this.getListingGraphqlInput(
          new PaginationGraphqlInput(page, rpp),
          new FiltersGraphqlInput(filters)
        )
      )
      .pipe(
        tap(({data}) => {
          const notificationsRes = data.notifications
          if(reset) this.notifications.next(notificationsRes.notifications)
          else this.notifications.next([...this.notifications.value, ...notificationsRes.notifications]);
          this.totalNotificationPages.next(notificationsRes.total / rpp);
          this.newNotificationsCount.next(notificationsRes.new);
        }),
        map(({data}) => data.notifications.notifications),
      )
  }

  /**

   This function returns a ListingGraphqlInputInterface that represents the input for a listing query.
   @param pagination The pagination information to include in the input.
   @returns The ListingGraphqlInputInterface for the listing query.
   */
  private getListingGraphqlInput(
    pagination: PaginationGraphqlInput,
    filters: FiltersGraphqlInput | null = null
  ) {
    return ListingGraphqlInput.wrap(filters, pagination);
  }
}
