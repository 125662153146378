import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  TextInputComponent,
  NumberInputComponent,
  RadioInputComponent,
  MaskInputComponent,
  PasswordInputComponent,
  DropdownInputComponent,
  DatePickerComponent,
  TimePickerInputComponent,
  CheckboxInputComponent,
  SliderInputComponent,
  RadioComponent,
} from "src/app/kernel/form/modules/inputs/simple-inputs/components";
import { InputTextModule } from "primeng/inputtext";
import { InputNumberModule } from "primeng/inputnumber";
import { InputMaskModule } from "primeng/inputmask";
import { PasswordModule } from "primeng/password";
import { FormErrorMessageModule } from "src/app/kernel/form/modules/tools/form-error-message/form-error-message.module";
import { DropdownModule } from "primeng/dropdown";
import { CalendarModule } from "primeng/calendar";
import { MultiSelectModule } from "primeng/multiselect";
import { CheckboxModule } from "primeng/checkbox";
import { TranslationModule } from "../../../../translations/modules/translation.module";
import { SliderModule } from "primeng/slider";
import { RestrictInputDirective } from "./directives/restrict-input.directive";
import { ColorPickerComponent } from "./components/color-picker/color-picker.component";
import { ColorPickerModule } from "primeng/colorpicker";
import { TextAreaComponent } from "./components/text-area/text-area.component";
import { VirtualContainerModule } from "src/app/kernel/general/virtual-conatiner/virtual-container.module";
import { SvgIconModule } from "../../../../tools/svg-icon/svg-icon.module";
import { InputTextareaModule } from "primeng/inputtextarea";
import { RadioButtonModule } from "primeng/radiobutton";
import { OtpInputComponent } from "./components/otp-input/otp-input.component";
import { EditorModule } from 'primeng/editor';
import { EditorInputComponent } from "./components/editor-input/editor-input.component";

@NgModule({
  declarations: [
    TextInputComponent,
    RadioInputComponent,
    MaskInputComponent,
    PasswordInputComponent,
    NumberInputComponent,
    DropdownInputComponent,
    DatePickerComponent,
    TimePickerInputComponent,
    CheckboxInputComponent,
    SliderInputComponent,
    RestrictInputDirective,
    ColorPickerComponent,
    TextAreaComponent,
    RadioComponent,
    OtpInputComponent,
    EditorInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorMessageModule,
    VirtualContainerModule,
    // Components {
    InputTextModule,
    InputNumberModule,
    PasswordModule,
    InputMaskModule,
    DropdownModule,
    MultiSelectModule,
    CalendarModule,
    CheckboxModule,
    SliderModule,
    ColorPickerModule,
    RadioButtonModule,
    // } Components
    TranslationModule,
    SvgIconModule,
    InputTextareaModule,
    EditorModule 
  ],
  exports: [
    TextInputComponent,
    NumberInputComponent,
    RadioInputComponent,
    MaskInputComponent,
    PasswordInputComponent,
    DropdownInputComponent,
    DatePickerComponent,
    TimePickerInputComponent,
    CheckboxInputComponent,
    SliderInputComponent,
    ColorPickerComponent,
    TextAreaComponent,
    RadioComponent,
    OtpInputComponent,
    EditorInputComponent
  ],
  providers: [],
  bootstrap: [],
})
export class SimpleInputsModule {}
