import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsRoutingModule } from './settings-routing.module';
import { SideNavService } from 'src/app/layout/side-nav/services/side-nav.service';
import { settingsSidenavConfig } from './settingsSidenav.config';
import { typesSettingsSidenavConfig } from '../fleet/cmSidenav.config';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SettingsRoutingModule
  ]
})
export class SettingsModule { 
  constructor(sideNavService:SideNavService){
    sideNavService.addSubMenuItems('setting',settingsSidenavConfig);
    sideNavService.addSubMenuItems('setting', typesSettingsSidenavConfig);
  }
}
