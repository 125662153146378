import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidatorDirective } from './directives/validator/validator.directive';
import { AsyncValidatorDirective } from './directives/async-validator/async-validator.directive';



@NgModule({
  declarations: [ValidatorDirective, AsyncValidatorDirective],
  imports: [
    CommonModule
  ],

  exports: [ValidatorDirective, AsyncValidatorDirective]
})
export class ValidatorModule { }
