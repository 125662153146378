import { Component, Input } from "@angular/core";

@Component({
  selector: "sxw-virtual-container",
  templateUrl: "./virtual-container.component.html",
  styleUrls: ["./virtual-container.component.scss"],
})
export class VirtualContainerComponent {
  @Input() position = "left";
  @Input() icon: string = "";
  @Input() height: string = "44";
  @Input() fillColor: string = "#fff";
  constructor() {}
}
