import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DropdownModule } from "primeng/dropdown";
import { KeyFilterModule } from "primeng/keyfilter";

import { SimpleInputsModule } from "../simple-inputs/simple-inputs.module";
import { IconsDropdownComponent } from './components/icons-dropdown/icons-dropdown.component';
import { FormErrorMessageModule } from '../../tools/form-error-message/form-error-message.module';

const DECLARATIONS = [IconsDropdownComponent];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
  imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      DropdownModule,
      KeyFilterModule,
      SimpleInputsModule,
      FormErrorMessageModule
  ]
})
export class IconsDropdownModule { }
