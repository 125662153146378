import {
  Component,
  EventEmitter,
  Host,
  Input,
  OnDestroy,
  Optional,
  Output,
  AfterViewInit,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomInput } from '../../../../../classes/custom-input';
import {SxwFormComponent} from "src/app/kernel/form/components/sxw-form/sxw-form.component";
import { Subscription } from 'rxjs';
import { Checkbox } from 'primeng/checkbox';

@Component({
  selector: 'sxw-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckboxInputComponent,
      multi: true,
    },
  ],
})
export class CheckboxInputComponent extends CustomInput<any> implements AfterViewInit, OnDestroy{
  private unsubscribe = new Subscription();

  @Input() binary = true;
  @Input() groupName = '';
  @Input() inputId = '';
  @Input() checkboxLabel = '';

  /* If value is array define item will be the value that will be added to the array */
  @Input() item: any;
  @Input() checkboxIcon = 'pi pi-check';
  @Input() override value: any;
  @Input() theme = 'sxw-theme';
  @Input() label!: string;
  @Output() onCheckboxChanged = new EventEmitter<any>();
  @ViewChild(Checkbox) checkbox?: Checkbox;

  get checked(){
    return this.checkbox?.checked?.() ?? false;
  }

  constructor(@Optional() @Host() public sxwForm?: SxwFormComponent) {
    super();
  }

  ngAfterViewInit(){
    if(this.groupName){
      this.observeGroupValue();
    }
  }

  override get summaryValue() {
    return this.value?.length ? this.checkboxLabel : this.value;
  }

  observeGroupValue(){
    const subscription = this.control?.valueChanges.subscribe((value: any) => {
      this.value = value;
    })
    this.unsubscribe.add(subscription);
  }

  onChangeValue(event: any) {
    this.onTouched();
    this.onChange(event.checked);
    this.onCheckboxChanged.emit(
      Array.isArray(event.checked) ? event.checked[0] : event.checked
    );
  }

  override ngOnDestroy(){
    super.ngOnDestroy();
    this.unsubscribe.unsubscribe()
  }
}
