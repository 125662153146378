import { NgModule } from '@angular/core';
import { MonitorRoutingModule } from './monitor-routing.module';

/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * NOTE THAT THIS MODULE WILL BE LOADED IN THE APP.
 * DO NOT IMPORT ANYTHING IN HERE.
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */
@NgModule({
  imports: [MonitorRoutingModule],
})
export class MonitorModule {}
