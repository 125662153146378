import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressesAutocompleteComponent } from 'src/app/kernel/form/modules/inputs/addresses-autocomplete/components/addresses-autocomplete/addresses-autocomplete.component';
import { FormsModule } from '@angular/forms';
import { FormErrorMessageModule } from '../../tools/form-error-message/form-error-message.module';
import { InputTextModule } from 'primeng/inputtext';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AddressesAutocompleteComponent],
  imports: [CommonModule, FormsModule, FormErrorMessageModule, InputTextModule, TranslateModule],
  exports: [AddressesAutocompleteComponent],
})
export class AddressesAutocompleteModule {}
