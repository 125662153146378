import { ICompleteCompanyRegistrationDTO } from "./../interfaces/company-register.interfaces";
import { LocalStorageService } from "src/app/kernel/tools/services/local-storage.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject, first, map } from "rxjs";
import { AuthUserModel } from "../models/AuthUser.model";
import { EUserType, EOAuthTarget, EOtpReason } from "../enums";
import {
  ICompleteRegistrationDTO,
  IRegisterCompanyDTO,
  ILoginDto,
  IObtainOAuthOpenId,
  IUserTokenType,
  IUser,
} from "../interfaces";
import { GraphService } from "src/app/kernel/graphql/services/graph.service";
import { Router } from "@angular/router";
import { EContactVerification } from "../enums/contact-verification.enum";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  public user$ = new BehaviorSubject<AuthUserModel | undefined>(undefined);
  public token$ = new BehaviorSubject<string | undefined>(undefined);

  userTokenTypeSelection: string[] = [
    "token",
    "user.id",
    "user.email",
    "user.mobile",
    "user.phone",
    "user.type",
    "user.registrationPhase",
    "user.isActive",
    "user.hasVerifiedMobile",
    "user.hasVerifiedEmail",
    "user.personalInfo.birthDate",
    "user.personalInfo.gender",
    "user.personalInfo.name",
    "user.personalInfo.profilePicture",
    "user.personalInfo.surename",
    "user.employeeOrigin.company.id",
    "user.employeeOrigin.company.name",
    "user.employeeOrigin.company.owner.id",
    "user.employeeOrigin.id",
    "user.createdAt",
    "user.updatedAt",
    "user.roles.permissions",
  ];

  constructor(
    private graphService: GraphService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {
    this.setRawUser(this.localStorageService.get<IUser>("user"));
    this.setToken(this.localStorageService.get<string>("token") ?? undefined);
  }

  login(loginData: ILoginDto) {
    return this.graphService
      .constructMutation<{ login: IUserTokenType }>(
        "login",
        { credentials: "LoginCredentialsInput!" },
        { credentials: loginData },
        this.userTokenTypeSelection
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.login }))
      );
  }

  verfiy2FA(otp: string) {
    return this.graphService
      .constructMutation<{ verify2fa: IUserTokenType }>(
        "verify2fa",
        { otp: "String!" },
        { otp: otp },
        this.userTokenTypeSelection
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.verify2fa }))
      );
  }

  verifyContactMethod(otp: string) {
    return this.graphService.constructMutation<{ verifyContactMethod: IUser }>(
      "verifyContactMethod",
      { otp: "String!" },
      { otp },
      [
        "id",
        "email",
        "mobile",
        "phone",
        "type",
        "registrationPhase",
        "isActive",
        "hasVerifiedMobile",
        "hasVerifiedEmail",
        "personalInfo.birthDate",
        "personalInfo.gender",
        "personalInfo.name",
        "personalInfo.profilePicture",
        "personalInfo.surename",
        "createdAt",
        "updatedAt",
        "roles.permissions",
      ]
    );
  }

  verifyRegistrationCredentials(otp: string) {
    return this.graphService
      .constructMutation<{ verifyRegistrationCredentials: IUserTokenType }>(
        "verifyRegistrationCredentials",
        { otp: "String!" },
        { otp: otp },
        this.userTokenTypeSelection
      )
      .pipe(
        first(),
        map((res) => ({
          ...res,
          data: res.data?.verifyRegistrationCredentials,
        }))
      );
  }

  verifyResetPassword(otp: string) {
    return this.graphService
      .constructMutation<{ verifyResetPassword: IUserTokenType }>(
        "verifyResetPassword",
        { otp: "String!" },
        { otp: otp },
        this.userTokenTypeSelection
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.verifyResetPassword }))
      );
  }

  resendOtp(reason: EOtpReason) {
    return this.graphService
      .constructMutation<{ resendOtp: { text: string; code: number } }>(
        "resendOtp",
        { reason: "EOtpReason!" },
        { reason: reason },
        ["text", "code"]
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.resendOtp }))
      );
  }

  initiateResetPassword(emailOrMobile: string, userType: EUserType) {
    return this.graphService
      .constructMutation<{ initiateResetPassword: IUserTokenType }>(
        "initiateResetPassword",
        { emailOrMobile: "String!", userType: " EUserType!" },
        { emailOrMobile, userType },
        this.userTokenTypeSelection
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.initiateResetPassword }))
      );
  }

  initiateContactVerification(type: EContactVerification) {
    return this.graphService.constructMutation(
      "initiateContactVerification",
      { type: "EContactVerificationType!" },
      { type },
      ["text", "code"]
    );
  }

  confirmResetPassword(password: string) {
    return this.graphService
      .constructMutation<{ confirmResetPassword: IUserTokenType }>(
        "confirmResetPassword",
        { password: "String!" },
        { password },
        this.userTokenTypeSelection
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.confirmResetPassword }))
      );
  }

  authRegistrationBySecret(secret: string) {
    return this.graphService
      .constructMutation<{ authRegistrationBySecret: IUserTokenType }>(
        "authRegistrationBySecret",
        { secret: "String!" },
        { secret },
        this.userTokenTypeSelection
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.authRegistrationBySecret }))
      );
  }

  completeRegistration(data: ICompleteRegistrationDTO) {
    return this.graphService
      .constructMutation<{ completeRegistration: IUserTokenType }>(
        "completeRegistration",
        { data: "CompleteRegistrationInput!" },
        { data },
        this.userTokenTypeSelection
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.completeRegistration }))
      );
  }

  completeCompanyRegistration(input: ICompleteCompanyRegistrationDTO) {
    return this.graphService
      .constructMutation<{ completeCompanyRegistration: IUserTokenType }>(
        "completeCompanyRegistration",
        { input: "CompleteCompanyRegistrationInput" },
        { input },
        this.userTokenTypeSelection
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.completeCompanyRegistration }))
      );
  }

  registerPassenger(input: {
    mobile: string;
    password: string;
    email?: string;
    referralCode?: string | null
  }) {
    return this.graphService
      .constructMutation<{ registerPassenger: IUserTokenType }>(
        "registerPassenger",
        { input: "RegisterPassengerInput" },
        { input },
        this.userTokenTypeSelection
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.registerPassenger }))
      );
  }

  registerCompany(input: IRegisterCompanyDTO) {
    return this.graphService
      .constructMutation<{ registerCompany: IUserTokenType }>(
        "registerCompany",
        { input: "RegisterCompanyInput" },
        { input },
        this.userTokenTypeSelection
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.registerCompany }))
      );
  }

  setRegistrationCredentials(credentials: {
    emailOrMobile: string;
    password: string;
  }) {
    return this.graphService
      .constructMutation<{ setRegistrationCredentials: IUserTokenType }>(
        "setRegistrationCredentials",
        { credentials: "CredentialsInput!" },
        { credentials },
        this.userTokenTypeSelection
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.setRegistrationCredentials }))
      );
  }

  initiateOAuth(target: EOAuthTarget) {
    return this.graphService
      .constructMutation<{ initiateOAuth: { authUrl: string } }>(
        "initiateOAuth",
        { target: "EOAuthTarget!" },
        { target },
        ["authUrl"]
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.initiateOAuth }))
      );
  }

  obtainOAuthOpenId(input: { code: string; state: string; target: string }) {
    return this.graphService
      .constructMutation<{ obtainOAuthOpenId: IObtainOAuthOpenId }>(
        "obtainOAuthOpenId",
        { input: "ObtainOAuthOpenIdInput!" },
        { input },
        [
          "idToken",
          "openIdData.email",
          "openIdData.emailVerified",
          "openIdData.id",
          "openIdData.name",
          "openIdData.surname",
          "openIdData.profilePicture",
          "userIsExists",
        ]
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.obtainOAuthOpenId }))
      );
  }

  oAuthRegisterCompany(input: {
    email: string;
    oauth: { idToken: string; target: EOAuthTarget };
  }) {
    return this.graphService
      .constructMutation<{ oAuthRegisterCompany: IUserTokenType }>(
        "oAuthRegisterCompany",
        { input: "OAuthRegisterCompanyInput!" },
        { input },
        this.userTokenTypeSelection
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.oAuthRegisterCompany }))
      );
  }

  oAuthLogin(input: { idToken: string; target: EOAuthTarget }) {
    return this.graphService
      .constructMutation<{ oAuthLogin: IUserTokenType }>(
        "oAuthLogin",
        { input: "OAuthCredentialsInput!" },
        { input },
        this.userTokenTypeSelection
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.oAuthLogin }))
      );
  }

  updateUser(user: IUser | null) {
    this.localStorageService.set("user", user);
    this.setRawUser(user);
  }

  updateUserAndToken(user: IUser | null, token: string) {
    this.updateUser(user);
    this.localStorageService.set("token", token);
    console.log("token is", token);
    this.setToken(token);
  }

  setRawUser(user: IUser | null): AuthService {
    this.user$.next(user ? AuthUserModel.create(user) : undefined);
    return this;
  }

  /**
   * Sets the current authenticated user.
   * @param user
   */
  setUser(user: AuthUserModel | undefined): AuthService {
    this.user$.next(user);
    return this;
  }

  /**
   * Returns the current authenticated user.
   */
  getUser(): AuthUserModel | undefined {
    return this.user$.value;
  }

  /**
   * Sets the user auth token.
   * @param token
   */
  setToken(token: string | undefined): AuthService {
    this.token$.next(token);
    return this;
  }

  /**
   * Returns the user auth token.
   */
  getToken(): string | undefined {
    return this.token$.value;
  }

  clearUser(): AuthService {
    this.localStorageService.remove("user").remove("token");
    this.setUser(undefined).setToken(undefined);
    return this;
  }

  logout(): AuthService {
    this.clearUser();
    this.router.navigate(["/auth/login"]);
    return this;
  }
}
