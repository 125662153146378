import {
  Component,
  forwardRef,
  Input,
  Host,
  Optional
} from "@angular/core";
import {
  NG_VALUE_ACCESSOR,
} from "@angular/forms";

import { CustomInput } from "../../../../../classes/custom-input";
import { QueryWithFiltersInterface } from "../../../../../../graphql/interfaces/query.interface";
import { IIcon } from "../../interfaces/icon.interface";
import { SxwFormComponent } from "src/app/kernel/form/components/sxw-form/sxw-form.component";
import { ScrollerOptions } from "primeng/scroller";

@Component({
  selector: "sxw-icons-dropdown",
  templateUrl: "./icons-dropdown.component.html",
  styleUrls: ["./icons-dropdown.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IconsDropdownComponent),
      multi: true,
    },
  ],
})
export class IconsDropdownComponent extends CustomInput<IIcon | null> {
  @Input() query!: QueryWithFiltersInterface;
  @Input() options: IIcon[] = [];
  @Input() theme = "sxw-theme";
  @Input() showClear:boolean = true;
  @Input() virtualScroll:boolean = false;
  @Input() virtualScrollOptions:ScrollerOptions = {}

  protected iconValue?: IIcon | null;
  protected get showValidationErrors(){
    return this.control && this.control.errors &&
      (this.control.touched || !!this.sxwForm?.submitted)
  }

  constructor(@Host() @Optional() protected sxwForm?: SxwFormComponent){
    super()
  }

  override get summaryValue(): string {
    return this.value ? `
      <div class="flex-row-end gap-5">
        <span><i class="${ this.value.iconClass }"></i></span>
        <span class="icon-text">${this.value.label ?? ''}</span>
      </div>
    ` : "";
  }

  /**
   * Capture changes from dropdown component to parent control.
   */
  handleDropDownChanges(value: IIcon | null): void {
    this.writeValue(value)
    this.onChange(value)
    this.onTouched()
  }

  override writeValue(value: IIcon | null): void {
    if(!(value?.iconClass && value?.label) ){
      value = null;
    }
    super.writeValue(value);
    this.iconValue = value
  }
}
