import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddPaymentMethodComponent } from './add-payment-method.component';
import { StripeExpressCheckoutComponent } from 'src/app/vogo-core/forms/add-payment-method/components/express-checkout.component';
import { TranslationModule } from 'src/app/kernel/translations/modules/translation.module';
import { FormModule } from 'src/app/kernel/form/form.module';
import { ButtonModule } from 'src/app/kernel/shared/modules/button/button.module';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';


@NgModule({
  declarations: [
    AddPaymentMethodComponent,
    StripeExpressCheckoutComponent,
  ],
  imports: [
    CommonModule,
    TranslationModule,
    FormModule,
    ButtonModule,
    NgxStripeModule
  ],
  exports:[AddPaymentMethodComponent]
})
export class AddPaymentMethodModule {
  static forRoot(stripeKey?: string): ModuleWithProviders<NgxStripeModule> {
    return NgxStripeModule.forRoot(stripeKey ?? environment.STRIP_KEY)
  }
 }
