import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormErrorMessageComponent
} from "src/app/kernel/form/modules/tools/form-error-message/components/form-error-message/form-error-message.component";
import {TranslationModule} from "../../../../translations/modules/translation.module";

@NgModule({
  declarations: [
    FormErrorMessageComponent
  ],
  imports: [ CommonModule, TranslationModule ],
  exports: [
    FormErrorMessageComponent
  ]
})
export class FormErrorMessageModule { }
