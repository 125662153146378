<div class="{{ theme }}">
  <span class="input-control__label" *ngIf="label">{{
    label | translate
  }}</span>
  <p-checkbox
    [inputId]="inputId"
    [name]="groupName"
    [(ngModel)]="value"
    [value]="item"
    [binary]="binary"
    [label]="checkboxLabel | translate"
    (onChange)="onChangeValue($event)"
    [checkboxIcon]="checkboxIcon"
    [disabled]="disabled"
    [readonly]="readonly"
  >
  </p-checkbox>
</div>
<div>
  <sxw-error-message
    *ngIf="
      control &&
      control.errors &&
      (control.touched || sxwForm?.submitted)
    "
    [message]="control ? control.errors : null"
  ></sxw-error-message>
</div>
