import { inject, Injectable } from "@angular/core";
import { GraphService } from "src/app/kernel/graphql/services/graph.service";
import { INotificationAction } from "../../interfaces/notification-action.interface";

/**
 * this service used to controls notification actions, that might change depending on the user type.
 * You can override this class with NotificationAction token:
 * - define a new class that should extend NotificationActionsService class then override actions you want.
 * - add new class you created with NoitificationAction token ex:
 *    ```NotificationModule.forRoot({notificationAction: [provide: NotificationAction, useClass: new CustomNotificationAction()]})```
 * 
 */
@Injectable({providedIn: 'root'})
export class NotificationActionsService implements INotificationAction {
  private graphService = inject(GraphService);

  addToken(token: string) {
    return this.graphService.constructMutation<any>(
      "addFirebaseToken",
      { firebaseToken: "CreateFirebaseTokenInput" },
      { firebaseToken: { token, device: navigator.userAgent } },
      ["code", "text"]
    )
  }

  deleteToken(token: string) {
    return this.graphService.constructMutation<{
      removeFirebaseToken: { code: number; text: string };
    }>("removeFirebaseToken", { firebaseToken: "String" }, { firebaseToken: token }, [
      "code",
      "text",
    ]);
  }
}