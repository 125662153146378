import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SimpleInputsModule } from "./modules/inputs/simple-inputs/simple-inputs.module";
import { SxwFormComponent } from "src/app/kernel/form/components/sxw-form/sxw-form.component";
import { FormErrorMessageModule } from "src/app/kernel/form/modules/tools/form-error-message/form-error-message.module";
import { AutocompleteSelectModule } from "src/app/kernel/form/modules/inputs/autocomplete-select/autocomplete-select.module";
import { AddressesAutocompleteModule } from "./modules/inputs/addresses-autocomplete/addresses-autocomplete.module";
import { MobileNumberModule } from "./modules/inputs/mobile-number/mobile-number.module";
import { VatInputModule } from "src/app/kernel/form/modules/inputs/vat-input/vat-input.module";
import { FiscalInputModule } from "src/app/kernel/form/modules/inputs/fiscal-input/fiscal-input.module";
import { FormStepperModule } from "./modules/tools/form-stepper/form-stepper.module";
import { ValidatorModule } from "./modules/tools/validators/validator.module";
import { FileModule } from "./modules/file/file.module";
import { IconsDropdownModule } from "./modules/inputs/icons-dropdown/icons-dropdown.module";
import { TreeDropdownModule } from "./modules/inputs/tree-dropdown/tree-dropdown.module";
import { FormControlOutletModule } from "./modules/tools/form-control-outlet/form-control-outlet.module";

@NgModule({
  declarations: [SxwFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // Modules {
    FormErrorMessageModule,
    SimpleInputsModule,
    AutocompleteSelectModule,
    AddressesAutocompleteModule,
    MobileNumberModule,
    VatInputModule,
    FiscalInputModule,
    FormStepperModule,
    FileModule,
    IconsDropdownModule,
    TreeDropdownModule,
    FormControlOutletModule,
    // } Modules
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    SxwFormComponent,
    // Modules {
    SimpleInputsModule,
    VatInputModule,
    FiscalInputModule,
    AddressesAutocompleteModule,
    AutocompleteSelectModule,
    MobileNumberModule,
    FormStepperModule,
    ValidatorModule,
    FileModule,
    IconsDropdownModule,
    TreeDropdownModule,
    FormControlOutletModule,
    // } Modules
  ],
})
export class FormModule {}
