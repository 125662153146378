import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { ButtonModule } from '../../../shared/modules/button/button.module';
import { FormErrorMessageModule } from '../tools/form-error-message/form-error-message.module';
import { FileItemComponent } from './components/file-item/file-item.component';
import { SpinnerModule } from '../../../shared/modules/spinner/spinner.module';
import { FormsModule } from '@angular/forms';
import {DownloadFilesModule} from "src/app/kernel/tools/download-files/download-files.module";
import {NgxFileDropModule} from "ngx-file-drop";
import { FilesDropZoneComponent } from './components/files-drop-zone/files-drop-zone.component';
import { FileTypeComponent } from './components/file-type/file-type.component';
import { TranslationModule } from 'src/app/kernel/translations/modules/translation.module';

@NgModule({
  declarations: [UploadFileComponent, FileItemComponent, FilesDropZoneComponent, FileTypeComponent],
    imports: [
        CommonModule,
        FormsModule,
        ButtonModule,
        FormErrorMessageModule,
        SpinnerModule,
        DownloadFilesModule,
        TranslationModule,
        NgxFileDropModule
    ],
    exports: [UploadFileComponent, FilesDropZoneComponent, FileItemComponent,FileTypeComponent],
})
export class FileModule {}
