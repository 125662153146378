import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { TreeSelectModule } from "primeng/treeselect";

import { TreeDropdownComponent } from './components/tree-dropdown.component';
import { FormErrorMessageModule } from '../../tools/form-error-message/form-error-message.module';
import { TranslationModule } from 'src/app/kernel/translations/modules/translation.module';
import { SvgIconModule } from "../../../../tools/svg-icon/svg-icon.module";

const DECLARATIONS = [TreeDropdownComponent];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
	imports: [
		CommonModule,
		FormsModule,
		TreeSelectModule,
		FormErrorMessageModule,
		TranslationModule,
		SvgIconModule,
	]
})
export class TreeDropdownModule { }
