import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import { EAccessType, EUserType } from 'src/app/vogo-core/enums';
import { accessTokenGuard } from 'src/app/vogo-core/guards/access-token.guard';

const routes: Route[] = [
  {
    path: 'monitoring',
    children: [
      {
        path: 'journeys',
        loadChildren: () =>
          import('./monitor-journeys/monitor-journeys.module').then(
            ({ MonitorJourneysModule }) => MonitorJourneysModule
          ),
        canActivate: [accessTokenGuard],
        data: {
          allowedAccessToken: EAccessType.FAT,
          allowedTypes: EUserType.Operator,
        },
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MonitorRoutingModule {}
