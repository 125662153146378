<ng-container [ngTemplateOutlet]="left"></ng-container>

<ng-template #left>
  <div class="d-flex container">
    <div class="custom-icon" [ngStyle]="{ height: height + 'px' }">
      <sxw-svg-icon [name]="icon" [fillColor]="fillColor"></sxw-svg-icon>
    </div>
    <div class="virtual-content">
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
