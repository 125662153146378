import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SxwStepComponent } from 'src/app/kernel/form/modules/tools/form-stepper/components/sxw-step/sxw-step.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FormStepperComponent } from 'src/app/kernel/form/modules/tools/form-stepper/components/form-stepper/form-stepper.component';
import { StepperIconsComponent } from './components/stepper-icons/stepper-icons.component';
import { SvgIconModule } from '../../../../tools/svg-icon/svg-icon.module';
import { SummaryComponent } from './components/summary/summary.component';
import { FormLabelDirective } from './directives/form-label/form-label.directive';
import { CheckTypePipe } from '../../../../shared/pipes/check-type/check-type.pipe';
import { TranslationModule } from '../../../../translations/modules/translation.module';
import { ButtonModule } from '../../../../shared/modules/button/button.module';
import {DownloadFilesModule} from "../../../../tools/download-files/download-files.module";
import { StepperTabsComponent } from './components/stepper-tabs/stepper-tabs.component';

@NgModule({
  declarations: [
    SxwStepComponent,
    FormStepperComponent,
    StepperIconsComponent,
    StepperTabsComponent,
    SummaryComponent,
    FormLabelDirective,
    CheckTypePipe,
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SvgIconModule,
        TranslationModule,
        ButtonModule,
        DownloadFilesModule,
        FormsModule,
    ],
    exports: [
        SxwStepComponent,
        FormStepperComponent,
        StepperIconsComponent,
        StepperTabsComponent,
        SummaryComponent,
        FormLabelDirective,
        CheckTypePipe,
    ],
})
export class FormStepperModule {}
